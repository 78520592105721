<template>
  <div class="terms-wrapper">
    <div class="terms-title">Умови використання</div>
    <p>
      <b>Умови використання сайту "Школа Біблії"</b><br />
      Увага! Перед переглядом Сайту уважно прочитайте ці умови. Якщо ви не погоджуєтесь з
      цими Умовами, не використовуйте Сайт.
    </p>

    <p>
      <b>Використання Сайту</b><br />
      Сайт дозволяє вам переглядати та завантажувати матеріали з нього (далі «Сайт»)
      тільки для особистого некомерційного використання, при збереженні вами всієї
      інформації про авторське право та інших відомостей про право власності, що містяться
      у вихідних матеріалах та будь-яких їх копіях. Заборонено змінювати матеріали цього
      Сайту, а також розповсюджувати або демонструвати їх у будь-якому вигляді або
      використовувати будь-яким іншим чином для комерційних цілей.
    </p>

    <p>
      <b>Відмова від відповідальності</b><br />
      Матеріали та послуги цього сайту надано без будь-яких гарантій та відповідальності
      за них з боку власників та адміністрації Сайту. Сайт не гарантує точність та повноту
      матеріалів, програм та послуг, що надаються на цьому Сайті. У будь-який час без
      повідомлення можуть вносити зміни до матеріалів та послуг, що надаються на цьому
      Сайті. Власники та адміністрація Сайту за жодних обставин не несуть відповідальності
      за будь-які збитки, що виникли внаслідок використання, неможливості використання або
      результатів використання цього сайту.
    </p>

    <p>
      <b>Реєстрація на сайті</b><br />
      Реєструючись на Сайті, Ви погоджуєтесь надати достовірну інформацію про себе та свої
      контактні дані.<br />
      Зворотній зв'язок та коментарі<br />
      Звертаючись до нас, ви несете відповідальність, що це повідомлення не є незаконним,
      шкідливим, загрозливим, наклепницьким, суперечить моральним нормам, порушує
      авторські права, пропагує ненависть та/або дискримінацію людей за расовою, етнічною,
      статевою, релігійною, соціальною ознаками, містить образи на адресу конкретних осіб
      чи організацій, а також якимось іншим чином порушує чинне законодавство України. Ви
      погоджуєтесь, що будь-яке ваше повідомлення адміністрація Сайту може видаляти без
      вашої згоди, а також безоплатно використовувати на свій розсуд. Власники та
      адміністрація сайту не несуть відповідальності за будь-яку інформацію, розміщену
      користувачами сайту.
    </p>

    <p>
      <b>Використання персональних даних</b><br />
      Ми використовуємо різні технології для збирання та зберігання інформації, коли ви
      відвідуєте Сайт. Це може включати запис одного або декількох куків або анонімних
      ідентифікаторів.
    </p>
  </div>
</template>
<style scoped>
.terms-wrapper {
  max-width: 1218px;
  padding: 60px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.terms-title {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  padding: 40px;
}
.terms-wrapper p {
  padding: 10px;
  font-size: 20px;
  line-height: 30px;
}
.terms-wrapper b {
  font-weight: 600;
}
</style>
